import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skills-section',
  templateUrl: './skills-section.component.html',
  styleUrls: ['./skills-section.component.sass']
})
export class SkillsSectionComponent implements OnInit {

  skillsGroup = [
      {
        "title" :"IDE",
        "items" : [ "VS Code", "Android Studio"]
      },
      {
        "title" :"Backend",
        "items" : [ "PHP","Node.Js", "Lumen", "Laravel", "Express"]
      },

      {
        "title" :"Frontend",
        "items" : [ "Bootstrap","Angular", "Ionic", "Angular Material", "PWA"]
      },
      {
        "title" :"Android",
        "items" : [ "MVVM", "Kotlin", "Java", "Rx", "Firebase", "Ads networks"]
      },
      {
        "title" :"Ios",
        "items" : [ "Swift","Alamofire", "Eureka"]
      },
      {
        "title" :"DevOps",
        "items" : [ "GCP","Kubernetes", "Netdata", "CloudRun", "Cloud Functions", "Cloud Build", "GitHub Actions"]
      },
      {
        "title": "BigData",
        "items": ["BigQuery", "Datastudio"]
      }
  ];
  constructor() { }

  ngOnInit() {
  }

}
