import { Component, OnInit } from '@angular/core';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faGlobeAfrica } from '@fortawesome/free-solid-svg-icons';
import { faBirthdayCake } from '@fortawesome/free-solid-svg-icons';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-info-personal-details-card',
  templateUrl: './info-personal-details-card.component.html',
  styleUrls: ['./info-personal-details-card.component.sass']
})
export class InfoPersonalDetailsCardComponent implements OnInit {
  faCoffee = faCoffee;
  faEnvelope = faEnvelope;
  faGlobeAfrica =faGlobeAfrica;
  faBirthdayCake = faBirthdayCake;
  faMapMarkedAlt = faMapMarkedAlt;
  
  ngOnInit() {
  }

}
