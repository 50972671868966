import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.sass']
})
export class BodyComponent implements OnInit {

  title = "Test"
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
    ){
    this.matIconRegistry.addSvgIcon(
      `linkedin2`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/linkedin2.svg")
    );

    this.matIconRegistry.addSvgIcon(
      `github`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/github2.svg")
    );

    this.matIconRegistry.addSvgIcon(
      `medium`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/medium2.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `fb`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/facebook2.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `location`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/location.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `birthday`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/birthday.svg")
    );

    this.matIconRegistry.addSvgIcon(
      `gmail`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/gmail2.svg")
    );
  }
  ngOnInit() {
  }

}
