import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from "@angular/common/http";
import { MglTimelineModule } from 'angular-mgl-timeline';
import { NgxSkillBarModule } from "ngx-skill-bar";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatGridListModule} from '@angular/material/grid-list';
import { BodyComponent } from './body/body.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { InfoCardComponent } from './info-card/info-card.component';
import {MatCardModule} from '@angular/material/card';
import { InfoImageCardComponent } from './info-image-card/info-image-card.component';
import { InfoPersonalDetailsCardComponent } from './info-personal-details-card/info-personal-details-card.component';
import {MatListModule} from '@angular/material/list';
import { MyBriefComponent } from './my-brief/my-brief.component';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import { EducationSectionComponent } from './education-section/education-section.component';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { WorkExperienceSectionComponent } from './work-experience-section/work-experience-section.component';
import { SkillsSectionComponent } from './skills-section/skills-section.component';
import {MatChipsModule} from '@angular/material/chips';
import { InterestSectionComponent } from './interest-section/interest-section.component';
import { ContactSectionComponent } from './contact-section/contact-section.component';
import { PortfoliosSectionComponent } from './portfolios-section/portfolios-section.component';
import {MatInputModule} from '@angular/material';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import {NgbPaginationModule, NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import {NgMediumModule} from 'ng-medium';
import { MediumArticlesSectionComponent } from './medium-articles-section/medium-articles-section.component';
import { LoadingSectionComponent } from './loading-section/loading-section.component';
import { LoadingHideComponent } from './loading-hide/loading-hide.component';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    BodyComponent,
    InfoCardComponent,
    InfoImageCardComponent,
    InfoPersonalDetailsCardComponent,
    MyBriefComponent,
    EducationSectionComponent,
    WorkExperienceSectionComponent,
    SkillsSectionComponent,
    InterestSectionComponent,
    ContactSectionComponent,
    PortfoliosSectionComponent,
    MediumArticlesSectionComponent,
    LoadingSectionComponent,
    LoadingHideComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatGridListModule,
    FlexLayoutModule,
    MatCardModule,
    MatListModule,
    MatButtonModule,
    MatExpansionModule,
    MatIconModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatChipsModule,
    MatInputModule,
    FontAwesomeModule,
    HttpClientModule,
    MglTimelineModule,
    BrowserAnimationsModule,
    NgxSkillBarModule,
    NgbPaginationModule, 
    NgbAlertModule,
    NgMediumModule

  
  ],
  exports: [
    MatIconModule
  ],
  providers: [],
  bootstrap: [HomeComponent]
})
export class AppModule { 
  constructor() {
    library.add(fas, far );
  }
}
