import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-education-section',
  templateUrl: './education-section.component.html',
  styleUrls: ['./education-section.component.sass']
})
export class EducationSectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
