import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-medium-articles-section',
  templateUrl: './medium-articles-section.component.html',
  styleUrls: ['./medium-articles-section.component.scss']
})
export class MediumArticlesSectionComponent implements OnInit {

  posts = []
  feed: Feed 
  constructor(private http: HttpClient) { 

  }

  ngOnInit() {
    this
    .http
    .get("https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/@abdulrahmanbabil/feed")
    .subscribe((response: MediumFeed) => {
      this.posts = response.items
      this.feed = response.feed
    })
  }

}


export declare class Feed {
  title: string;
  description?: string;
  author?: string;
  image?: string;
  link?: string;
  url?: string;
  items?: Item[];
}

export declare class Item {
  title: string;
  author?: string;
  categories?: string[];
  content?: string;
  description?: string;
  guid?: string;
  link?: string;
  pubDate?: Date;
  thumbnail?: string;
}

export declare class MediumFeed {
  feed: Feed;
  items: Item[];
}
