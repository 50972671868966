import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: [  './info-card.component.sass']
})
export class InfoCardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
